/**
 * Let op! Deze is van de _NIEUWE_ PAGE BUILDER.
 */

import docReady from '../../../assets/js/docReady';

class NavigationComponent {
    constructor(element) {
        this.element = element; // The navigation component Dom element

        this.subNavDynamicHeight = 0;
        this.mainNavAnimationDuration = 400;
        this.subnavAnimationDuration = 650; // Value in MS for subnav slide animation
    }

    init() {
        this.element.classList.add('strapped');
        // Force override of css vars to ensure matching timing with js
        this.element.style.setProperty('--subnav-transition-duration', `${this.subnavAnimationDuration}ms`);
        this.element.style.setProperty('--main-nav-transition-duration', `${this.mainNavAnimationDuration}ms`);

        this.elements = {
            overlayToggle: this.element.querySelector('input.overlay-toggle'),
            movingNavContainer: this.element.querySelector('.dynamic-nav-container'),
            mainNav: this.element.querySelector('.main-nav'),
            headerNavigation: this.element.querySelector('#header-navigation'),
            mainLinks: [...this.element.querySelectorAll('.main-link.with-subnav')],
            navToggle: this.element.querySelector('#header-navigation-toggle'),
        };

        this.bootstrap();
    }

    bootstrap() {
        this.elements.navToggle?.addEventListener('click', () => {
            this.elements.overlayToggle.click();
        });

        // Alle open/close functionaliteit gaat via de hidden input van de overlay abstract
        // Als je nav wilt openen/closen, zet de `checked` value van deze overlayToggle
        this.elements.overlayToggle.addEventListener('change', (event) => {
            if (event.target.checked) {
                this.openMainNav();
            } else {
                this.closeMainNav();
            }
        });

        this.elements.mainLinks.forEach((link) => {
            link.addEventListener('click', () => {
                this.toggleSubnav(link);
            });
        });

        this.element.querySelectorAll('.to-main-link')
            .forEach((element) => {
                element.addEventListener('click', () => {
                    this.unToggleSubnav(element);
                });
            });
    }

    // Open the main navigation. After animations, remove display and class
    openMainNav() {
        this.logEvent('open-main-nav');

        document.documentElement.classList.add('no-scroll');
        this.elements.headerNavigation.style.display = null;
        this.elements.headerNavigation.ariaHidden = false;

        if (this.elements.movingNavContainer.classList.contains('subnav-active')) {
            this.setNavHeight(this.element.querySelector('.subnav-container.active').clientHeight);
        } else {
            this.setNavHeight(this.elements.mainNav.clientHeight);
        }
        this.element.classList.add('nav-active');
    }

    // Close the main navigation. After animations, remove display and class
    closeMainNav() {
        this.logEvent('close-main-nav');

        this.setNavHeight(0);
        this.element.classList.remove('nav-active');
        setTimeout(() => {
            document.documentElement.classList.remove('no-scroll');
            this.elements.headerNavigation.style.display = 'none';
            this.elements.headerNavigation.ariaHidden = true;
        }, this.mainNavAnimationDuration);
    }

    // Slide the active sub nav into view. Set height of the container div because absolute positioning
    toggleSubnav(parentLink) {
        this.logEvent('open-subnav', { subnav: parentLink.querySelector('.label').innerHTML });

        this.elements.movingNavContainer.classList.add('subnav-active');
        const subnav = parentLink.nextElementSibling;
        subnav.style.display = null;
        subnav.ariaHidden = false;
        subnav.classList.add('active');

        this.setMainLinkTabIndex(-1);
        this.setNavHeight(subnav.clientHeight);
    }

    // Slide the nav (back) to main nav. After animations, remove display and class
    unToggleSubnav(childLink) {
        this.logEvent('close-subnav');

        this.elements.movingNavContainer.classList.remove('subnav-active');
        const subnav = childLink.closest('.subnav-container');
        this.setNavHeight(this.elements.mainNav.clientHeight);

        setTimeout(() => {
            subnav.style.display = 'none';
            subnav.ariaHidden = true;
            subnav.classList.remove('active');
            this.setMainLinkTabIndex(0);
        }, this.subnavAnimationDuration);
    }

    // Sets the height variable to eiter:
    //      0 - nav is closed
    //      height of the main-nav - default state for pages that are not in child menu's
    //      height of ths active subnav - when opening subnav or default on pages that are in the child menu
    setNavHeight(newNavHeight) {
        this.subNavDynamicHeight = `${newNavHeight}px`;
        this.element.style.setProperty('--dynamic-nav-height', this.subNavDynamicHeight);
    }

    setMainLinkTabIndex(tabIndex) {
        this.elements.mainNav.querySelectorAll('.main-link')
            .forEach((link) => {
                link.tabIndex = tabIndex;
            });
    }

    // eslint-disable-next-line class-methods-use-this
    logEvent(event, eventData) {
        if (typeof window.bananas !== 'undefined') {
            window.bananas('event', `navigation.${event}`, eventData);
        }
    }
}

docReady(() => {
    const navigationComponent = document.querySelector('.navigation-component.header');
    if (navigationComponent && !navigationComponent.classList.contains('strapped')) {
        const component = new NavigationComponent(navigationComponent);
        component.init(navigationComponent);
    }
});
